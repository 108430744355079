import '../sass/main.scss';

import LazyLoad from 'vanilla-lazyload';
var marquee = require('jquery.marquee');

var siteGlobal = {
  initReady: function () {
  },
  initLoad: function () {
  	this.lazyLoad();
    this.menuInit();
    this.moveScroller();
    this.homeScrollAnim();
    this.videoPlay();
    this.schedaSlider();
    this.pageMarqueeInit();
    this.bottleRotate();
    // this.letterSplit();
    this.accordionInit();
    // this.propsAnim();
  },
  initScroll: function(){
    this.moveScroller();
  },
  lazyLoad: function(){
    var ll = new LazyLoad({
        elements_selector: '.lazy',
        use_native: true
    })
  },
  menuInit: function(){
    $('.header__hamburger').on('click', function(){
      $('.header__hamburger, .header').toggleClass('opened');
    })
  },
  moveScroller: function() {
    if ($('.page_home').length) { 

      if ($(window).outerWidth(true) > 767) { 
        var anchor = $("#comp"),
            scroller = $('.scroll-sequence__container'),
            st = $(window).scrollTop(),
            ot = anchor.offset().top - 300;

        if(st > ot) {
            scroller.css({
                position: "absolute",
                top: ot
            });
        } else {
            scroller.css({
                position: "fixed",
                top: ""
            });
        }
      } else {
        var anchor = $("#comp"),
            scroller = $('.scroll-sequence__container'),
            st = $(window).scrollTop();

        if(st > 500) {
            scroller.css({
                position: "absolute",
                top: 500
            });
        } else {
            scroller.css({
                position: "fixed",
                top: ""
            });
        }
      }

    }
  },
  homeScrollAnim: function(){
    if ($('.apple-sequence').length) {
      if ($(window).outerWidth(true) > 767) {
        var imgRoot = 'https://zimor.s3.eu-central-1.amazonaws.com/bottle/' 
      } else {
        var imgRoot = 'https://zimor.s3.eu-central-1.amazonaws.com/bottle-small/' 
      }


      var homeBottle = new ScrollSequence({
        container: '.apple-sequence',
        scrollWith: '.apple-container',
        images: bottleImages,
        imagesRoot: imgRoot,
        priorityFrames: [0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20],
        cover: false,
        playUntil: 'scroll-out',
        starts: 'in'
      });
    }
  },
  bottleRotate: function(){
    if ($('.page_home').length) {    
      var controller = new ScrollMagic.Controller(),
        tl = new TimelineMax(),
        bot = $('.scroll-sequence canvas');

        if ($(window).outerWidth(true) > 991) {
          var bottleRot = tl.fromTo(bot, 1, {transform: "rotate(12deg) scale(.8)", right: 0}, {transform: "rotate(-18deg) scale(.8)", right: "80%"});
        } else if ($(window).outerWidth(true) > 767 && $(window).outerWidth(true) < 992) {
          var bottleRot = tl.fromTo(bot, 1, {transform: "rotate(12deg) scale(.8)", right: 0}, {transform: "rotate(-18deg) scale(.8)", right: "120%"});
        } else {
          var bottleRot = tl.fromTo(bot, 1, {transform: "rotate(16deg) scale(1)", right: "-10%"}, {transform: "rotate(-18deg) scale(1)", right: "55%"});
        }
      var scene = new ScrollMagic.Scene({duration: $(window).outerHeight(true), triggerElement: $('#trig')}).setTween(bottleRot).addTo(controller);
    }
  },
  videoPlay: function(){
    $('#play, video').on('click', function(){
      var vid = $('#vid');
      

      $(vid).on('ended', function(){
        vid[0].currentTime = 0;
        $('#play').fadeIn(200);
        $('#scroll').fadeIn(200);
      });



      if(vid[0].paused){
        vid[0].play();
        $('#play').fadeOut(200);
        $('#scroll').fadeOut(200);
      } else {
        vid[0].pause();
        $('#play').fadeIn(200);
        $('#scroll').fadeIn(200);
      }
    })
  },
  schedaSlider: function(){
    if ($('.tech__slider').length && $(window).outerWidth(true) > 767) { 
      $('.tech__slider').not('.slick-initialized').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        dots: false,
        autoplay: false,
        speed: 800,
        infinite: true,
        fade: true,
        touchMove: false,
        adaptiveHeight: true
      });
      $('.tech__slider').on('beforeChange', function(event, slick, currentSlide, nextSlide){
              
        var controller = new ScrollMagic.Controller(),
          tl = new TimelineMax(),
          bot = $('.tech__anim');

        if (currentSlide == 1) {
          var bottleAnim = tl.to(bot, 1, {transform: "rotate(14deg) scale(1.3)"});
        } else if (currentSlide == 2) {
          var bottleAnim = tl.to(bot, 1, {transform: "rotate(20deg) scale(1.05)"});
        } else if (currentSlide == 0) {
          var bottleAnim = tl.to(bot, 1, {transform: "rotate(-10deg) scale(1.3)"});
        }

        var scene = new ScrollMagic.Scene({reverse: true, duration: 300}).setTween(bottleAnim).addTo(controller);
      });

      $('#prev').on('click', function(){
        $('.tech__slider').slick('slickPrev');
      })
      $('#next').on('click', function(){
        $('.tech__slider').slick('slickNext');
      })
    }


    if ($('.tech__slider').length && $(window).outerWidth(true) < 768) {
    }
  },
  pageMarqueeInit: function(){
    $('.tech__marquee').marquee({
      direction: 'left',
      duration: 8000,
      gap: 20,
      startVisible: true,
      duplicated: true,
    });
  },
  // letterSplit: function(){
  //   $('.car__main').html(function (i, html) {
  //       var chars = $.trim(html).split("");
  //       return '<span>' + chars.join('</span><span>') + '</span>';
  //     });
  // },
  accordionInit: function(){
    $('.acc__trig').on('click', function(){
      $(this).toggleClass('active').siblings('.acc__coll').slideToggle(200);
    })
  },
  propsAnim: function(){
     var controller = new ScrollMagic.Controller(),
      tl = new TimelineMax(),
      propsEl = $('.props__block'),
      propsAn = tl.staggerTo(propsEl , .4, {y:0, opacity:1}, .15),
      scene = new ScrollMagic.Scene({reverse: true, triggerElement: $('#props_trig')[0]}).setTween(propsAn).addTo(controller);
  },
  rebuildAllEvents: function(){
    this.initLoad();
  },
};

global.siteGlobal = siteGlobal;


(function () {
  siteGlobal.initReady();
  $(window).on('load', function() {
    // $('#preloader').addClass('loaded');
    siteGlobal.initLoad();
  });

  $(window).on('scroll', function(){
    siteGlobal.initScroll();
  })
}());